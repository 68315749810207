/* eslint-disable use-isnan */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { call, put, takeEvery, delay } from 'redux-saga/effects';

// Business-point state
import {
  LIST,
  LIST_VM,
  INIT_CREATE,
  CREATE,
  READ_INFO,
  UPDATE_INFO,
  UPDATE_CALIBRATION,
  DELETE,
  HISTORY,
  READ_PRODUCT,
  LIST_PRODUCT,
  CLEAR_CASH,
  UPDATE_PLANOGRAM_MCPRO,
  UPDATE_PLANOGRAM_MILANO,
  UPDATE_PLANOGRAM_RETAIL,
  UPDATE_PLANOGRAM_LEVENDING,
  UPDATE_PLANOGRAM,
  UPDATE_PLANOGRAM_RETAIL_OLD,
  UPDATE_PLANOGRAM_ARCADE,
  UPDATE_PLANOGRAM_FRANKE,
  READ_PLANOGRAM_RETAIL,
  READ_PLANOGRAM_LEVENDING,
  READ_PLANOGRAM_RETAIL_OLD,
  READ_PLANOGRAM_MILANO,
  READ_PLANOGRAM,
  READ_PLANOGRAM_FRANKE,
  READ_PLANOGRAM_ARCADE,
  READ_PLANOGRAM_MCPRO,
  STOCK_BUSINESS_POINT,
  NOTIFICATIONS,
  CLOSE_NOTIFICATIONS,
  UPDATE_SHARE_REVENUE,
  GET_SHARE_REVENUE,
  READ_PLANOGRAM_WATER_DISPENSER,
  UPDATE_PLANOGRAM_WATER_DISPENSER,
  RESET_FLOWMETER,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import {
  setPlanogramArcade,
  device_map as deviceMap,
  sensors,
  setPlanogram_franke,
  setPlanogram_Milano,
  setConfig3,
  setConfig,
  setRetail,
  getPlanoggram,
  stockPlanogram,
  stockEmpty,
  get_notifications,
  close_notification,
  getPlanoggramLevending,
  setplanogram_levending,
  post_task_bp,
  update_business_point,
  setPlanogramMCPro,
  clearCash,
  getTime,
  getPlanogramMilano,
  setPlanogramWater,
  resetFlowmeter,
  applyTags,
  removeTags,
  setHistoryStock
} from 'Apps/device';

import {
  forceDeepSet,
  has,
  safeDeepDel,
  safeDeepGet,
  safeDeepSet,
  safeDel,
} from 'iotera-base/utility/json';
import { isEmpty } from 'iotera-base/utility/text';
import Result from 'iotera-base/result';
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest';
import { APPLICATION_ID } from 'Apps';

function* list_product() {
  yield put(loading(LIST_PRODUCT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.product.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const productMap = wsResult.body;
    const productList = [];
    for (const id in productMap) {
      let productName = safeDeepGet(productMap, [id, 'displayName']);
      let productID = id;
      productList.push({ label: productName, value: productID });
    }
    yield put(
      result(LIST_PRODUCT, { code: Result.SUCCESS, body: productList })
    );
    return;
  }
  yield put(result(LIST_PRODUCT, { result: { code: Result.FAILED } }));
}
function* list_vm() {
  yield put(loading(LIST_VM));
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.serialnumber.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const distributorMap = wsResult.body;
    const distributorList = [];
    let i = 0;
    for (const sku in distributorMap) {
      distributorMap[sku].id = sku;
      safeDeepSet(distributorMap, [sku, 'id'], i++);
      distributorList.push(distributorMap[sku]);
    }
    yield put(result(LIST_VM, { code: Result.SUCCESS, body: distributorList }));
    return;
  }
  yield put(result(LIST_VM, { result: { code: Result.FAILED } }));
}
function* list(payload) {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  const data = safeDeepGet(payload, ['payload']);
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  let allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  let wsResult = yield call(firebaseInstance.businessPoint.list, data);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointBody = wsResult.body;

    const businessPointMap = {};
    for (const id in businessPointBody) {
      const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
      businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
    }

    wsResult = yield call(deviceMap);
    if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
      const devices = safeDeepGet(wsResult, ['body', 'devices']);
      const businessPointList = [];
      let i = 0;
      for (const deviceId in businessPointMap) {
        const online = safeDeepGet(
          devices,
          [deviceId, 'online', 'is_online'],
          false
        );
        const serial_number = safeDeepGet(
          devices,
          [deviceId, 'serial_number'],
          '-'
        );
        const latest_online = safeDeepGet(
          devices,
          [deviceId, 'online', 'last_online_ts'],
          0
        );
        const latest_offline = safeDeepGet(
          devices,
          [deviceId, 'online', 'last_offline_ts'],
          0
        );
        safeDeepSet(businessPointMap, [deviceId, 'last_online'], latest_online);
        safeDeepSet(
          businessPointMap,
          [deviceId, 'last_offline'],
          latest_offline
        );
        safeDeepSet(businessPointMap, [deviceId, 'id'], i++);
        safeDeepSet(businessPointMap, [deviceId, 'online'], online);
        safeDeepSet(
          businessPointMap,
          [deviceId, 'serial_number'],
          serial_number
        );
        if (allBp == 'true') {
          businessPointList.push(businessPointMap[deviceId]);
        } else if (allBp == 'false') {
          if (arr_bp.includes(deviceId)) {
            businessPointList.push(businessPointMap[deviceId]);
          }
        }
      }
      yield put(
        result(LIST, { code: Result.SUCCESS, body: businessPointList })
      );
      return;
    }
  }
  yield put(result(LIST, { code: Result.FAILED }));
}

function* initCreate() {
  yield put(loading(INIT_CREATE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') === Result.SUCCESS) {
    const businessPointBody = wsResult.body;

    const businessPointMap = {};
    for (const id in businessPointBody) {
      const device_id = safeDeepGet(businessPointBody, [id, 'device_id'], '');
      businessPointMap[device_id] = safeDeepGet(businessPointBody, id);
    }

    wsResult = yield call(deviceMap);
    if (safeDeepGet(wsResult, 'code') === Result.HTTP.OK) {
      const devices = safeDeepGet(wsResult, ['body', 'devices']);
      const unregisteredDevices = [];
      for (const deviceId in devices) {
        if (!has(businessPointMap, deviceId)) {
          unregisteredDevices.push({
            label: safeDeepGet(devices, [deviceId, 'serial_number'], '-'),
            value: deviceId,
          });
        }
      }
      yield put(
        result(INIT_CREATE, {
          code: 0,
          body: { unregistered_devices: unregisteredDevices },
        })
      );
      return;
    }
  }
  yield put(result(INIT_CREATE, { code: Result.FAILED }));
}

function createBp(id, name) {
  var result = JPOST({
    url: 'https://pay.iotera.io/web/device/update',
    headers: {
      'Iotera-Payment-Application-Id': APPLICATION_ID,
      'Iotera-Payment-Admin-Token':
        '6bf868df24a4357dee20e6d3d6ef1d1944249ad44cb29687446d346f60fc0215',
    },
    body: {
      device_id: id,
      name: name,
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
  return result;
}

function* create(action) {
  yield put(loading(CREATE));
  const id = safeDeepGet(action, ['payload', 'id']);
  const name = safeDeepGet(action, ['payload', 'name']);

  // Build vm_code
  const sn = safeDeepGet(action, ['payload', 'sn']);
  const end = sn.length;
  const start = end - 4;
  const codesn = sn.substring(start, end);
  const endapp = APPLICATION_ID.length;
  const startapp = endapp - 4;
  const appSn = APPLICATION_ID.substring(startapp, endapp);
  const vm_code = appSn + '-' + codesn;

  const payload = safeDeepGet(action, 'payload');
  yield call(post_task_bp, payload, id);
  const data = {
    device_id: btoa(id),
    device_name: btoa(name),
    vm_code: btoa(vm_code),
  };
  yield call(update_business_point, data);
  safeDel(payload, 'id');
  const firebaseInstance = getInstance();
  yield call(createBp, id, name);
  let wsResult = yield call(firebaseInstance.businessPoint.create, id, payload);
  const tag = payload['tags'];
  // const tags = []
  // for(const i in tag){
  //   console.log(i)
  //   console.log(tag)
  //   const data = tag[i]
  //   if(data.value != ""){
  //     tags.push("location:"+data.value)
  //   }
  // }
  const bodyPayload = {
    id: id,
    tags: 'location:' + tag,
    // "tags": tags
  };
  if (tag == '') {
    safeDeepDel(bodyPayload, 'tags');
    const latestTags = payload['latestTags'];
    safeDeepSet(bodyPayload, 'tags', ['location:' + latestTags]);
    yield call(removeTags, bodyPayload);
  } else {
    yield call(applyTags, bodyPayload);
  }
  yield delay(1000);
  // const body = safeDeepGet(action, "payload", {});
  // const device_id = safeDeepGet(body, "device_id", "")
  // const type = safeDeepGet(body, "typeShare", "")
  // const share_amount = safeDeepGet(body, "share_amount", "")
  // const updatedValue ={}
  // if(type == 'fixed'){
  //   updatedValue['type'] = type
  //   updatedValue['share_amount'] = Number(share_amount);
  // } else if(type == "share_revenue"){
  //   updatedValue['type'] = type
  //   updatedValue['share_amount'] = Number(1- share_amount/100);
  // }
  // updatedValue["id"]= device_id
  // wsResult = yield call(firebaseInstance.businessPoint.updateFee, updatedValue);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(CREATE, { code }));
}

function* readInfo(action) {
  yield put(loading(READ_INFO));
  const firebaseInstance = getInstance();
  let wsResult = yield call(deviceMap);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(result(READ_INFO, { code: Result.FAILED }));
    return;
  }
  const devices = safeDeepGet(wsResult, ['body', 'devices']);

  const registeredDevices = [];
  for (const deviceId in devices) {
    registeredDevices.push({
      label: safeDeepGet(devices, [deviceId, 'serial_number'], '-'),
      value: deviceId,
    });
  }

  const deviceId = safeDeepGet(action, ['payload', 'id']);
  wsResult = yield call(firebaseInstance.businessPoint.read, deviceId);
  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_INFO, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ_INFO, {
      code: 0,
      body: { info: wsResult.body, registered_devices: registeredDevices },
    })
  );
}

function* updateInfo(action) {
  yield put(loading(UPDATE_INFO));
  const id = safeDeepGet(action, ['payload', 'id']);
  const name = safeDeepGet(action, ['payload', 'name']);

  // Build vm_code
  const sn = safeDeepGet(action, ['payload', 'sn']);
  const end = sn.length;
  const start = end - 4;
  const codesn = sn.substring(start, end);
  const endapp = APPLICATION_ID.length;
  const startapp = endapp - 4;
  const appSn = APPLICATION_ID.substring(startapp, endapp);
  const vm_code = appSn + '-' + codesn;

  const payload = safeDeepGet(action, 'payload');
  safeDel(payload, 'id');
  const firebaseInstance = getInstance();
  const data = {
    device_id: btoa(id),
    device_name: btoa(name),
    vm_code: btoa(vm_code),
  };
  yield call(update_business_point, data);
  // yield call(post_task_bp, payload, id);
  yield call(createBp, id, name);
  let wsResult = yield call(firebaseInstance.businessPoint.update, id, payload);
  const tag = payload['tags'];
  // const tags = []
  // for(const i in tag){
  //   console.log(i)
  //   console.log(tag)
  //   const data = tag[i]
  //   if(data.value != ""){
  //     tags.push("location:"+data.value)
  //   }
  // }
  const bodyPayload = {
    id: id,
    tags: 'location:' + tag,
    // "tags": tags
  };
  if (tag == '') {
    safeDeepDel(bodyPayload, 'tags');
    const latestTags = payload['latestTags'];
    safeDeepSet(bodyPayload, 'tags', ['location:' + latestTags]);
    yield call(removeTags, bodyPayload);
  } else {
    yield call(applyTags, bodyPayload);
  }
  yield delay(1000);
  const code = safeDeepGet(wsResult, 'code');
  // const body = safeDeepGet(action, "payload", {});
  // const device_id = safeDeepGet(body, "device_id", "")
  // const type = safeDeepGet(body, "typeShare", "")
  // const share_amount = safeDeepGet(body, "share_amount", "")
  // const updatedValue ={}
  // if(type == 'fixed'){
  //   updatedValue['type'] = type
  //   updatedValue['share_amount'] = Number(share_amount);
  // } else if(type == "share_revenue"){
  //   updatedValue['type'] = type
  //   updatedValue['share_amount'] = Number(1- share_amount/100);
  // }
  // updatedValue["id"]= device_id
  // wsResult = yield call(firebaseInstance.businessPoint.updateFee, updatedValue);

  yield delay(1000);
  yield put(result(UPDATE_INFO, { code }));
  yield call(firebaseInstance.bpfee.update, id, name);
}
function* updatecalibration(action) {
  yield put(loading(UPDATE_CALIBRATION));
  const id = safeDeepGet(action, ['payload', 'docId']);
  const payload = safeDeepGet(action, 'payload');
  safeDel(payload, 'docId');
  const firebaseInstance = getInstance();
  const wsResult = yield call(
    firebaseInstance.businessPoint.updatecalibration,
    id,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(UPDATE_CALIBRATION, { code }));
}

function* readproduct(action) {
  yield put(loading(READ_PRODUCT));
  const sku = safeDeepGet(action, ['payload', 'id', 'id']);
  const device = safeDeepGet(action, ['payload', 'id', 'deviceId']);
  const firebaseInstance = getInstance();
  const wsResult = yield call(
    firebaseInstance.planogram.readproduct,
    sku,
    device
  );
  const ingredientsMaps = wsResult.body.ingredients;
  for (const id in ingredientsMaps) {
    var type = safeDeepGet(ingredientsMaps[id], ['type']);
    var amount = safeDeepGet(ingredientsMaps[id], ['amount']);
    safeDeepSet(wsResult.body, [id, 'type'], type);
    safeDeepSet(wsResult.body, [id, 'amount'], amount);
  }

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PRODUCT, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ_PRODUCT, {
      code: 0,
      body: wsResult.body,
    })
  );
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const firebaseInstance = getInstance();
  const deviceId = safeDeepGet(payload, ['payload', 'deviceId'], '');
  const wsResult = yield call(firebaseInstance.businessPoint.delete_, deviceId);
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(DELETE, { code }));
}

function* list_history(action) {
  const payload = safeDeepGet(action, 'payload');
  yield put(loading(HISTORY));
  const device_id = safeDeepGet(payload, 'device_id', null);
  const firebaseInstance = getInstance();
  let wsResult = yield call(
    firebaseInstance.planogram.list,
    device_id,
    payload
  );
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const historyBody = safeDeepGet(wsResult, 'body');
    wsResult = yield call(firebaseInstance.businessPoint.list);
    const businessPointBody = wsResult.body;
    const businessPointMap = {};
    for (const id in businessPointBody) {
      const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
      businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
    }
    const historyList = [];
    for (const id in historyBody) {
      const body = {};
      const planogramBody = safeDeepGet(historyBody, [id, 'planogram']);
      const historyPlanogram = [];
      if (planogramBody.device_type === 'mcprov2') {
        for (const i in planogramBody) {
          if (typeof planogramBody[i] === 'object') {
            planogramBody[i]['key'] = i;
            historyPlanogram.push(planogramBody[i]);
          }
        }
      } else {
        for (const i in planogramBody) {
          planogramBody[i.toString()]['key'] = i;
          historyPlanogram.push(planogramBody[i]);
        }
      }

      const device_id = safeDeepGet(historyBody, [id, 'id']);
      const name = safeDeepGet(businessPointMap, [device_id, 'name'], '');
      body['planogram'] = historyPlanogram;
      body['id'] = safeDeepGet(historyBody, [id, 'id']);
      body['name'] = safeDeepGet(historyBody, [id, 'name'], name);
      body['user'] = safeDeepGet(historyBody, [id, 'user']);
      body['time_stamp'] = Number(id);
      historyList.push(body);
    }
    yield put(result(HISTORY, { code: Result.SUCCESS, body: historyList }));
  } else {
    yield put(result(HISTORY, { code: Result.FAILED }));
  }
}

function* clear_cash(action) {
  yield put(loading(CLEAR_CASH));
  const device_id = safeDeepGet(action, ['payload', 'device_id'], '');
  const payload = {
    device_id: device_id,
    clear_cash: true,
  };
  const wsResult = yield call(clearCash, payload);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.HTTP.OK) {
    yield put(result(CLEAR_CASH, { code: Result.SUCCESS, message: wsResult }));
    return;
  }
  yield put(result(CLEAR_CASH, { code: Result.FAILED, message: wsResult }));
}

function* updatePlanogram(action) {
  yield put(loading(UPDATE_PLANOGRAM));
  const id = safeDeepGet(action, ['payload', 'id']);
  const planogram = safeDeepGet(action, ['payload', 'planogram']);
  if (id == null || id == undefined) {
    yield put(result(UPDATE_PLANOGRAM, { code: -1 }));
    return;
  }
  const stockMap = {};
  const idMap = {};
  const priceMap = {};
  const nameMap = {};
  const activeMap = {};
  const columnMap = {};
  for (const selection in planogram) {
    if (selection.slice(0, 1) == 'K') {
      const stock = safeDeepGet(planogram, [selection, 'stock'], 0);
      const product = safeDeepGet(planogram, [selection, 'product'], '');
      const name = safeDeepGet(planogram, [selection, 'name'], '');
      const price = safeDeepGet(planogram, [selection, 'price'], 0);
      const active = safeDeepGet(planogram, [selection, 'active'], false);
      if (!isEmpty(product)) {
        idMap[selection] = product;
        nameMap[selection] = name;
      }
      priceMap[selection] = price;
      activeMap[selection] = active;
      stockMap[selection] = stock;
    } else {
      const column = safeDeepGet(planogram, [selection, 'column']);
      columnMap[selection] = column;
    }
  }
  if (
    idMap == null ||
    nameMap == null ||
    priceMap == null ||
    idMap == undefined ||
    nameMap == undefined ||
    priceMap == undefined
  ) {
    yield put(result(UPDATE_PLANOGRAM, { code: -2 }));
    return;
  }

  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }

  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');
  const planogramFb = {};
  for (const selection in planogram) {
    if (selection.slice(0, 1) == 'K') {
      planogramFb[selection] = safeDeepGet(planogram, selection);
    }
  }
  const tsNow = new Date().getTime();
  wsResult = yield call(
    setConfig3,
    id,
    idMap,
    nameMap,
    priceMap,
    activeMap,
    columnMap,
    stockMap,
    tsNow
  );

  // Save stock to platform
  // wsResult = yield call(setStock, id, stockMap);
  // Delay
  yield delay(1000);
  // Save price to platform
  const historyPlanogram = [];
  if (planogramFb.device_type === 'mcprov2') {
    for (const i in planogramFb) {
      if (typeof planogramFb[i] === 'object') {
        planogramFb[i]['key'] = i;
        historyPlanogram.push(planogramFb[i]);
      }
    }
  } else {
    for (const i in planogramFb) {
      planogramFb[i.toString()]['key'] = i;
      historyPlanogram.push(planogramFb[i]);
    }
  }
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    if (safeDeepGet(resultBody, 'result') == 10) {
      resultBody['message'] = 'Mesin Tidak ada Jaringan';
    }
    if (safeDeepGet(resultBody, 'result') == 7) {
      resultBody['message'] = 'Mesin tidak membalas perintah';
    }
    if (safeDeepGet(resultBody, 'result') == 4) {
      resultBody['message'] = 'Kesalahan pada data planogram';
    }
    yield put(
      result(UPDATE_PLANOGRAM, { code: -3, message: resultBody, ts: tsNow })
    );
    return;
  }
  const type = safeDeepGet(action, ['payload', 'device_type']);

  yield call(firebaseInstance.planogram.create, id, planogramFb, nameBp, type);
  const body = safeDeepGet(action, 'payload',{});
  safeDeepSet(body,'email', localStorage.getItem("email"))
  const ss = yield call(setHistoryStock, body)
  yield put(
    result(UPDATE_PLANOGRAM, {
      code: Result.SUCCESS,
      message: resultBody,
      ts: tsNow,
      planogram_history: historyPlanogram,
      name: nameBp,
    })
  );
  // Save stock to platform
}
function* updatePlanogramRetailOld(action) {
  yield put(loading(UPDATE_PLANOGRAM_RETAIL_OLD));
  const id = safeDeepGet(action, ['payload', 'id']);
  const planogram = safeDeepGet(action, ['payload', 'planogram'], null);
  if (planogram == null || planogram == undefined) {
    yield put(
      result(UPDATE_PLANOGRAM_RETAIL_OLD, {
        code: -1,
        message: 'Planogam undefined or null',
      })
    );
    return;
  }
  const stockMap = {};
  const idMap = {};
  const priceMap = {};
  const nameMap = {};
  const activeMap = {};
  const activeProductMap = {};
  const columnMap = {};
  const imageMap = {};
  const orderMap = {};
  const groupMap = {};
  const descriptionMap = {};
  // const stockst = {};
  for (const selection in planogram) {
    if (selection.slice(0, 1) == 'P') {
      const product = safeDeepGet(planogram, [selection, 'product'], '');
      const name = safeDeepGet(planogram, [selection, 'name'], '');
      const image = safeDeepGet(
        planogram,
        [selection, 'image'],
        'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/bottleBlank.png?alt=media&token=be603fed-c9c9-4783-bf7e-d015cafc9a64'
      );
      const price = safeDeepGet(planogram, [selection, 'price'], 0);
      const active = safeDeepGet(planogram, [selection, 'active'], false);
      const column = safeDeepGet(planogram, [selection, 'column']);
      const group = safeDeepGet(planogram, [selection, 'group']);
      const order = safeDeepGet(planogram, [selection, 'order'], 0);
      if (!isEmpty(product)) {
        idMap[selection] = product;
        nameMap[selection] = name;
      }
      activeProductMap[selection] = active;
      if (active == false || active == 'false' || active == 'False') {
        columnMap[selection] = '';
      } else {
        columnMap[selection] = column;
      }
      priceMap[selection] = price;
      // groupMap[selection] = group;
      if (image == null) {
        imageMap[selection] =
          'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/bottleBlank.png?alt=media&token=be603fed-c9c9-4783-bf7e-d015cafc9a64';
      } else {
        imageMap[selection] = image;
      }
      orderMap[selection] = Number(order);
    } else {
      const stock = safeDeepGet(planogram, [selection, 'stock'], 0);
      const active = safeDeepGet(planogram, [selection, 'active'], false);
      activeMap[selection] = active;
      stockMap[selection] = stock;
    }
  }
  if (
    idMap == null ||
    nameMap == null ||
    priceMap == null ||
    idMap == undefined ||
    nameMap == undefined ||
    priceMap == undefined
  ) {
    yield put(
      result(UPDATE_PLANOGRAM_RETAIL_OLD, {
        code: -2,
        message: 'Name or Price undefined or Null',
      })
    );
    return;
  }
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }
  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');
  const planogramFb = {};
  for (const selection in planogram) {
    planogramFb[selection] = safeDeepGet(planogram, selection);
  }
  const tsNow = new Date().getTime();
  wsResult = yield call(
    setRetail,
    id,
    idMap,
    nameMap,
    priceMap,
    activeMap,
    columnMap,
    stockMap,
    imageMap,
    orderMap,
    activeProductMap,
    groupMap,
    descriptionMap,
    tsNow
  );
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  const historyPlanogram = [];
  if (planogramFb.device_type === 'mcprov2') {
    for (const i in planogramFb) {
      if (typeof planogramFb[i] === 'object') {
        planogramFb[i]['key'] = i;
        historyPlanogram.push(planogramFb[i]);
      }
    }
  } else {
    for (const i in planogramFb) {
      planogramFb[i.toString()]['key'] = i;
      historyPlanogram.push(planogramFb[i]);
    }
  }
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    if (safeDeepGet(resultBody, 'result') == 10) {
      resultBody['message'] = 'Mesin Tidak ada Jaringan';
    }
    if (safeDeepGet(resultBody, 'result') == 7) {
      resultBody['message'] = 'Mesin tidak membalas perintah';
    }
    if (safeDeepGet(resultBody, 'result') == 4) {
      resultBody['message'] = 'Kesalahan pada data planogram';
    }
    yield put(
      result(UPDATE_PLANOGRAM_RETAIL_OLD, {
        code: -3,
        message: resultBody,
        ts: tsNow,
        planogram_history: historyPlanogram,
        name: nameBp,
      })
    );
    return;
  }

  // Delay
  yield delay(1000);
  const type = safeDeepGet(action, ['payload', 'device_type']);

  yield call(firebaseInstance.planogram.create, id, planogramFb, nameBp, type);
  const body = safeDeepGet(action, 'payload',{});
  safeDeepSet(body,'email', localStorage.getItem("email"))
  const ss = yield call(setHistoryStock, body)
  yield put(
    result(UPDATE_PLANOGRAM_RETAIL_OLD, {
      code: Result.SUCCESS,
      message: resultBody,
      ts: tsNow,
      planogram_history: historyPlanogram,
      name: nameBp,
    })
  );
}
function* updatePlanogramRetail(action) {
  yield put(loading(UPDATE_PLANOGRAM_RETAIL));
  const id = safeDeepGet(action, ['payload', 'id']);
  const type = safeDeepGet(action, ['payload', 'device_type']);
  const planogram = safeDeepGet(action, ['payload', 'planogram'], null);
  const dataPrice = safeDeepGet(action, ['payload', 'dataPrice'], null);
  const firebaseInstance = getInstance();

  // Retrieve product
  let wsResult = yield call(firebaseInstance.product.list);

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM, { code: Result.FAILED }));
    return;
  }
  const productImageResult = {};
  const productCategory = {};
  const productDescription = {};
  const productMap = safeDeepGet(wsResult, ['body']);
  for (const sku in productMap) {
    productImageResult[sku] = safeDeepGet(productMap, [sku, 'img_url'], '');
    productCategory[sku] = safeDeepGet(productMap, [sku, 'category'], '');
    productDescription[sku] = safeDeepGet(productMap, [sku, 'desc'], '');
  }

  if (planogram == null || planogram == undefined) {
    yield put(
      result(UPDATE_PLANOGRAM_RETAIL, {
        code: -1,
        message: 'Planogam undefined or null',
      })
    );
    return;
  }

  const stockMap = {};
  const idMap = {};
  const priceMap = {};
  const nameMap = {};
  const activeMap = {};
  let activeProductMap = {};
  const columnMap = {};
  const imageMap = {};
  const orderMap = {};
  const groupMap = {};
  const descriptionMap = {};
  // const stockst = {};
  const arr_product = []; // P1, P2, dll
  const sku_product = []; // SKU product
  const validator_product = {}; // Sku value button
  const validator_active_product = {}; //Active Product
  const validator_selection_product = {}; //Active Product
  const column_MapValidator = {};
  const validator_Column = {}; // Json untuk column button
  const selection_clear = []; // Array untuk membersihkan column pada button
  for (const selection in planogram) {
    // Check if selection P1, P2, dll
    if (selection.slice(0, 1) == 'P') {
      selection_clear.push(selection);
      const product = safeDeepGet(planogram, [selection, 'product'], '');
      const column = safeDeepGet(planogram, [selection, 'column'], '');
      var active = safeDeepGet(planogram, [selection, 'active'], false);
      if (column == '') {
        active = false;
      }
      safeDeepSet(validator_active_product, selection, active);
      // Check if product not null or '-'
      if (product == '' || product == '-') {
        continue;
      } else {
        // check if selection not in arr_product
        if (!arr_product.includes(selection)) {
          arr_product.push(selection);
        }
        if (!sku_product.includes()) {
          sku_product.push(product);
        }
        if (column != '') {
          // safeDeepSet(column_MapValidator, selection, column.split(','));
          safeDeepSet(validator_Column, selection, column.split(','));
        }
        if (
          safeDeepGet(validator_selection_product, selection, '') != product
        ) {
          safeDeepSet(validator_product, product, selection);
          safeDeepSet(validator_selection_product, selection, product);
        }
      }
    }
  }
  let i = 0;
  for (const selection in planogram) {
    if (selection.slice(0, 1) == 'P') {
      safeDeepSet(planogram, [selection, 'column'], '');
      const active = safeDeepGet(planogram, [selection, 'active'], false);
      const order = safeDeepGet(planogram, [selection, 'order'], 0);
      if (safeDeepGet(activeProductMap, selection, '') == '') {
        activeProductMap[selection] = active;
      }
      orderMap[selection] = Number(order);
    } else {
      const active = safeDeepGet(planogram, [selection, 'active'], false);
      const stock = active
        ? safeDeepGet(planogram, [selection, 'stock'], 0)
        : 0;
      activeMap[selection] = active;
      stockMap[selection] = stock;
      const product = active
        ? safeDeepGet(planogram, [selection, 'product'], '-')
        : '';
      const name = active
        ? safeDeepGet(planogram, [selection, 'name'], '')
        : '';
      const image = active
        ? safeDeepGet(
            productImageResult,
            product,
            'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/bottleBlank.png?alt=media&token=be603fed-c9c9-4783-bf7e-d015cafc9a64'
          )
        : 'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/bottleBlank.png?alt=media&token=be603fed-c9c9-4783-bf7e-d015cafc9a64';
      const group = active ? safeDeepGet(productCategory, product, '') : '';
      const description = active
        ? safeDeepGet(productDescription, product, '')
        : '';
      const price = active
        ? safeDeepGet(planogram, [selection, 'price'], 0)
        : 0;

      if (product == '' || product == '-') {
        continue;
      } else {
        if (sku_product.includes(product)) {
          const selection_product = safeDeepGet(validator_product, product, '');
          if (selection_product != '') {
            safeDeepSet(
              column_MapValidator,
              [selection_product, i++],
              selection
            );
            if (!isEmpty(product)) {
              idMap[selection_product] = product;
              nameMap[selection_product] = name;
              priceMap[selection_product] = price;
              descriptionMap[selection_product] = description;
              if (image == null || image == '') {
                imageMap[selection_product] =
                  'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/bottleBlank.png?alt=media&token=be603fed-c9c9-4783-bf7e-d015cafc9a64';
              } else {
                imageMap[selection_product] = image;
              }
              groupMap[selection_product] = group;
            }
          }
        } else {
          sku_product.push(product);
          let selection_product = '';
          const not_active_button = [];
          for (const select in validator_active_product) {
            const selection_active = validator_active_product[select];
            if (!selection_active || selection_active == 'false') {
              not_active_button.push(select);
              safeDeepSet(column_MapValidator, select, []);
            }
          }
          if (type == 'reyeah') {
            for (let j = 1; j <= 11; j++) {
              if (!arr_product.includes('P' + j)) {
                selection_product = 'P' + j;
                if (j < 11) {
                  arr_product.push(selection_product);
                  safeDeepSet(
                    validator_active_product,
                    selection_product,
                    true
                  );
                  break;
                }
              }
            }
          } else {
            for (let j = 1; j <= 46; j++) {
              if (!arr_product.includes('P' + j)) {
                selection_product = 'P' + j;
                if (j < 46) {
                  arr_product.push(selection_product);
                  safeDeepSet(
                    validator_active_product,
                    selection_product,
                    true
                  );
                  break;
                }
              }
            }
          }
          if (selection_product == 'P46') {
            selection_product = not_active_button[0];
            safeDeepSet(validator_active_product, selection_product, true);
            arr_product.push(selection_product);
            safeDel(activeProductMap, selection_product);
            safeDeepSet(activeProductMap, selection_product, true);
          }
          safeDeepSet(validator_product, product, selection_product);
          arr_product.push(selection_product);
          safeDel(activeProductMap, selection_product);
          safeDeepSet(activeProductMap, selection_product, true);
          safeDeepSet(column_MapValidator, [selection_product, i++], selection);

          if (!isEmpty(product)) {
            idMap[selection_product] = product;
            nameMap[selection_product] = name;
            priceMap[selection_product] = price;
            descriptionMap[selection_product] = description;
            groupMap[selection_product] = group;

            if (image == null) {
              imageMap[selection_product] =
                'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/bottleBlank.png?alt=media&token=be603fed-c9c9-4783-bf7e-d015cafc9a64';
            } else {
              imageMap[selection_product] = image;
            }
          }
        }
      }
    }
  }
  for (const i in selection_clear) {
    const selection = selection_clear[i];
    columnMap[selection] = '';
  }
  for (const id in column_MapValidator) {
    let column = '';
    const data_arr = safeDeepGet(column_MapValidator, id, []);
    for (const j in data_arr) {
      const str = data_arr[j];
      if (Number(j) + 1 == data_arr.length) {
        column += str;
      } else {
        column += str + ',';
      }
    }
    columnMap[id] = column;
  }
  if (
    idMap == null ||
    nameMap == null ||
    priceMap == null ||
    idMap == undefined ||
    nameMap == undefined ||
    priceMap == undefined
  ) {
    yield put(
      result(UPDATE_PLANOGRAM_RETAIL, {
        code: -2,
        message: 'Name or Price undefined or Null',
      })
    );
    return;
  }
  wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }

  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');
  const planogramFb = {};
  for (const selection in planogram) {
    planogramFb[selection] = safeDeepGet(planogram, selection);
    if (selection.slice(0, 1) == 'P') {
      safeDeepSet(planogramFb, [selection, 'column'], columnMap[selection]);
    }
  }
  const tsNow = new Date().getTime();
  wsResult = yield call(
    setRetail,
    id,
    idMap,
    nameMap,
    priceMap,
    activeMap,
    columnMap,
    stockMap,
    imageMap,
    orderMap,
    activeProductMap,
    groupMap,
    descriptionMap,
    tsNow
  );
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  const historyPlanogram = [];
  if (planogramFb.device_type === 'mcprov2') {
    for (const i in planogramFb) {
      if (typeof planogramFb[i] === 'object') {
        planogramFb[i]['key'] = i;
        historyPlanogram.push(planogramFb[i]);
      }
    }
  } else {
    for (const i in planogramFb) {
      planogramFb[i.toString()]['key'] = i;
      historyPlanogram.push(planogramFb[i]);
    }
  }
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    if (safeDeepGet(resultBody, 'result') == 10) {
      resultBody['message'] = 'Mesin Tidak ada Jaringan';
    }
    if (safeDeepGet(resultBody, 'result') == 7) {
      resultBody['message'] = 'Mesin tidak membalas perintah';
    }
    if (safeDeepGet(resultBody, 'result') == 4) {
      resultBody['message'] = 'Kesalahan pada data planogram';
    }
    const body = safeDeepGet(action, 'payload',{});
    safeDeepSet(body,'email', localStorage.getItem("email"))
    const ss = yield call(setHistoryStock, body)
    yield put(
      result(UPDATE_PLANOGRAM_RETAIL, {
        code: -3,
        message: resultBody,
        ts: tsNow,
        planogram_history: historyPlanogram,
        name: nameBp,
      })
    );
    return;
  }
  // Delay
  yield delay(1000);
  for (const i in dataPrice) {
    const data = safeDeepGet(dataPrice, i, {});
    yield call(firebaseInstance.product.updatePrice, data);
  }
  const timeResult = yield call(getTime);
  const time_stamp = safeDeepGet(timeResult, ['body', 'timestamp'], '');
  yield call(
    firebaseInstance.planogram.create,
    id,
    planogramFb,
    nameBp,
    time_stamp
  );
  yield put(
    result(UPDATE_PLANOGRAM_RETAIL, {
      code: Result.SUCCESS,
      message: resultBody,
      ts: tsNow,
      planogram_history: historyPlanogram,
      name: nameBp,
    })
  );
}
function* updatePlanogramMCPro(action) {
  yield put(loading(UPDATE_PLANOGRAM_MCPRO));
  const id = safeDeepGet(action, ['payload', 'device_id']);
  const type = safeDeepGet(action, ['payload', 'device_type']);
  const payload = safeDeepGet(action, 'payload', {});
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }
  const pulse = safeDeepGet(payload, 'pulse', 10);
  const number = Number(pulse);
  safeDel(payload, 'pulse');
  safeDeepSet(payload, 'pulse', number);
  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');
  const cash_price = safeDeepGet(payload, 'cash_price', []);
  const qris_price = safeDeepGet(payload, 'qris_price', []);
  const cash_map = {};
  const qris_map = {};
  for (const i in cash_price) {
    const price = safeDeepGet(cash_price[i], 'price', '');
    const duration = safeDeepGet(cash_price[i], 'duration', '');
    if (price == '' || duration == '') {
      continue;
    } else {
      cash_map[price] = Number(duration);
    }
  }
  for (const i in qris_price) {
    const price = safeDeepGet(qris_price[i], 'price', '');
    const duration = safeDeepGet(qris_price[i], 'duration', '');

    if (price == '' || duration == '') {
      continue;
    } else {
      qris_map[price] = Number(duration);
    }
  }

  safeDel(payload, 'cash_price');
  safeDel(payload, 'qris_price');
  safeDeepSet(payload, 'cash_price', cash_map);
  safeDeepSet(payload, 'qris_price', qris_map);
  // Save price to platform
  wsResult = yield call(setPlanogramMCPro, payload);
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  const historyPlanogram = [];
  if (payload.device_type === 'mcprov2') {
    for (const i in payload) {
      if (typeof payload[i] === 'object') {
        payload[i]['key'] = i;
        historyPlanogram.push(payload[i]);
      }
    }
  } else {
    for (const i in payload) {
      payload[i.toString()]['key'] = i;
      historyPlanogram.push(payload[i]);
    }
  }
  const tsNow = new Date().getTime();
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    if (safeDeepGet(resultBody, 'result') == 10) {
      resultBody['message'] = 'Mesin Tidak ada Jaringan';
    }
    if (safeDeepGet(resultBody, 'result') == 7) {
      resultBody['message'] = 'Mesin tidak membalas perintah';
    }
    if (safeDeepGet(resultBody, 'result') == 4) {
      resultBody['message'] = 'Kesalahan pada data planogram';
    }

    yield put(
      result(UPDATE_PLANOGRAM_MCPRO, {
        code: Result.FAILED,
        message: resultBody,
        ts: tsNow,
        planogram_history: historyPlanogram,
        name: nameBp,
      })
    );
    return;
  }
  yield call(firebaseInstance.planogram.create, id, payload, nameBp, type);

  yield put(
    result(UPDATE_PLANOGRAM_MCPRO, {
      code: Result.SUCCESS,
      message: resultBody,
      ts: tsNow,
      planogram_history: historyPlanogram,
      name: nameBp,
    })
  );
}
function* updatePlanogramFranke(action) {
  yield put(loading(UPDATE_PLANOGRAM_FRANKE));
  const payload = safeDeepGet(action, ['payload', 'planogram']);
  const deviceId = safeDeepGet(action, ['payload', 'deviceId']);
  const config = [];
  const selection = Object.keys(payload[0]);
  config.push(selection);
  for (const i in payload) {
    const arrData = [];
    const se = payload[i].selection;
    const sku = payload[i].sku;
    const name = payload[i].name;
    const price = Number(payload[i].price);
    if (
      se != null ||
      se != undefined ||
      se != '' ||
      sku != null ||
      sku != undefined ||
      name != null ||
      name != undefined ||
      price != NaN ||
      price != undefined
    ) {
      arrData.push(se);
      arrData.push(sku);
      arrData.push(name);
      arrData.push(price);
      config.push(arrData);
    }
  }
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }
  const nameBp = safeDeepGet(businessPointMap, [deviceId, 'name'], '-');
  const planogramFb = {};
  for (const selection in payload) {
    planogramFb[selection] = payload[selection];
  }
  wsResult = yield call(setPlanogram_franke, deviceId, config);
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  const historyPlanogram = [];
  if (planogramFb.device_type === 'mcprov2') {
    for (const i in planogramFb) {
      if (typeof planogramFb[i] === 'object') {
        planogramFb[i]['key'] = i;
        historyPlanogram.push(planogramFb[i]);
      }
    }
  } else {
    for (const i in planogramFb) {
      planogramFb[i.toString()]['key'] = i;
      historyPlanogram.push(planogramFb[i]);
    }
  }
  const tsNow = new Date().getTime();
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.HTTP.OK || code == Result.SUCCESS) {
    const code = safeDeepGet(wsResult, 'code');
    yield call(
      firebaseInstance.planogram.create,
      deviceId,
      planogramFb,
      nameBp
    );

    yield put(
      result(UPDATE_PLANOGRAM_FRANKE, { code, message: resultBody, ts: tsNow })
    );
  } else {
    yield put(
      result(UPDATE_PLANOGRAM_FRANKE, {
        code: Result.FAILED,
        message: resultBody,
        ts: tsNow,
        planogram_history: historyPlanogram,
        name: nameBp,
      })
    );
  }
}
function* updatePlanogramLevending(action) {
  yield put(loading(UPDATE_PLANOGRAM_LEVENDING));
  const firebaseInstance = getInstance();
  const payload = safeDeepGet(action, ['payload', 'planogram']);
  const deviceId = safeDeepGet(action, ['payload', 'deviceId']);
  const config = [];
  const selection = Object.keys(payload[0]);
  config.push(selection);
  config[0].push('hot');
  config[0].push('ice');
  config[0].push('ingredient');
  config[0].push('name');
  config[0].push('image');
  let wsResult = yield call(firebaseInstance.product.list);
  const productMap = wsResult.body;
  const productList = [];
  let i = 0;
  for (const sku in productMap) {
    safeDeepSet(productMap, [sku, 'id'], i++);
    productList.push(sku);
  }
  for (const i in payload) {
    const sku = payload[i].sku;

    if (sku != undefined) {
      const arrData = [];
      const se = payload[i].selection;
      const order = Number(payload[i].order);
      const price = Number(payload[i].price);
      let stringActive = payload[i].active;
      if (typeof stringActive == 'string') {
        stringActive = payload[i].active.toLowerCase();
      } else {
        stringActive = payload[i].active;
      }
      let active = false;
      if (
        stringActive == 'TRUE' ||
        stringActive == 'true' ||
        stringActive == true
      ) {
        active = true;
      }
      const image = safeDeepGet(
        productMap,
        [sku, 'img_url'],
        'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/bottleBlank.png?alt=media&token=be603fed-c9c9-4783-bf7e-d015cafc9a64'
      );
      const name = safeDeepGet(productMap, [sku, 'name'], '-');
      const hot_value = safeDeepGet(productMap, [sku, 'hot'], 'false');
      const water = safeDeepGet(productMap, [sku, 'water'], 0);
      const instant1 = safeDeepGet(productMap, [sku, 'instant1'], 0);
      const out_time_instant1 = safeDeepGet(
        productMap,
        [sku, 'out_time_instant1'],
        0
      );
      const water_instant1 = safeDeepGet(
        productMap,
        [sku, 'water_instant1'],
        0
      );
      const stir_time_instant1 = safeDeepGet(
        productMap,
        [sku, 'stir_time_instant1'],
        0
      );
      const instant2 = safeDeepGet(productMap, [sku, 'instant2'], 0);
      const out_time_instant2 = safeDeepGet(
        productMap,
        [sku, 'out_time_instant2'],
        0
      );
      const water_instant2 = safeDeepGet(
        productMap,
        [sku, 'water_instant2'],
        0
      );
      const stir_time_instant2 = safeDeepGet(
        productMap,
        [sku, 'stir_time_instant2'],
        0
      );
      const instant3 = safeDeepGet(productMap, [sku, 'instant3'], 0);
      const out_time_instant3 = safeDeepGet(
        productMap,
        [sku, 'out_time_instant3'],
        0
      );
      const water_instant3 = safeDeepGet(
        productMap,
        [sku, 'water_instant3'],
        0
      );
      const stir_time_instant3 = safeDeepGet(
        productMap,
        [sku, 'stir_time_instant3'],
        0
      );
      const instant4 = safeDeepGet(productMap, [sku, 'instant4'], 0);
      const out_time_instant4 = safeDeepGet(
        productMap,
        [sku, 'out_time_instant4'],
        0
      );
      const water_instant4 = safeDeepGet(
        productMap,
        [sku, 'water_instant4'],
        0
      );
      const stir_time_instant4 = safeDeepGet(
        productMap,
        [sku, 'stir_time_instant4'],
        0
      );
      const instant5 = safeDeepGet(productMap, [sku, 'instant5'], 0);
      const out_time_instant5 = safeDeepGet(
        productMap,
        [sku, 'out_time_instant5'],
        0
      );
      const water_instant5 = safeDeepGet(
        productMap,
        [sku, 'water_instant5'],
        0
      );
      const stir_time_instant5 = safeDeepGet(
        productMap,
        [sku, 'stir_time_instant5'],
        0
      );
      let ice = { no: 0, less: 100, medium: 140, many: 170 };

      let hot = false;
      if (hot_value == 'true') {
        hot == true;
        ice = {};
      }

      let data_instant1 = 'sugar';
      if (instant1 != 'sugar') {
        data_instant1 = {
          out_time: out_time_instant1, //second
          water: water_instant1, //ml
          stir_time: stir_time_instant1, //second
        };
      }
      let data_instant2 = 'sugar';
      if (instant2 != 'sugar') {
        data_instant2 = {
          out_time: out_time_instant2, //second
          water: water_instant2, //ml
          stir_time: stir_time_instant2, //second
        };
      }
      let data_instant3 = 'sugar';
      if (instant3 != 'sugar') {
        data_instant3 = {
          out_time: out_time_instant3, //second
          water: water_instant3, //ml
          stir_time: stir_time_instant3, //second
        };
      }
      let data_instant4 = 'sugar';
      if (instant4 != 'sugar') {
        data_instant4 = {
          out_time: out_time_instant4, //second
          water: water_instant4, //ml
          stir_time: stir_time_instant4, //second
        };
      }
      let data_instant5 = 'sugar';
      if (instant5 != 'sugar') {
        data_instant5 = {
          out_time: out_time_instant5, //second
          water: water_instant5, //ml
          stir_time: stir_time_instant5, //second
        };
      }
      const ingredient = {
        coffee: {
          water: water, //ml
        },
        instant1: data_instant1,
        instant2: data_instant2,
        instant3: data_instant3,
        instant4: data_instant4,
        instant5: data_instant5,
      };
      if (sku != undefined) {
        if (productList.includes(sku)) {
          arrData.push(se);
          arrData.push(sku);
          arrData.push(price);
          arrData.push(order);
          arrData.push(active);
          arrData.push(hot_value);
          arrData.push(ice);
          arrData.push(ingredient);
          arrData.push(name);
          arrData.push(image);
          config.push(arrData);
        } else {
          yield put(result(UPDATE_PLANOGRAM_LEVENDING, { code: '-4' }));
          return;
        }
      }
    }
  }
  wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }
  const nameBp = safeDeepGet(businessPointMap, [deviceId, 'name'], '-');
  const planogramFb = {};
  for (const selection in payload) {
    const sku = payload[selection].sku;
    if (sku != undefined) {
      planogramFb[selection] = payload[selection];
    }
  }
  wsResult = yield call(setplanogram_levending, deviceId, config);
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  const historyPlanogram = [];
  if (planogramFb.device_type === 'mcprov2') {
    for (const i in planogramFb) {
      if (typeof planogramFb[i] === 'object') {
        planogramFb[i]['key'] = i;
        historyPlanogram.push(planogramFb[i]);
      }
    }
  } else {
    for (const i in planogramFb) {
      planogramFb[i.toString()]['key'] = i;
      historyPlanogram.push(planogramFb[i]);
    }
  }
  const code = safeDeepGet(wsResult, 'code');
  const ts = new Date().getTime();
  const tsNow = new Date().getTime();
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    yield call(
      firebaseInstance.planogram.create,
      deviceId,
      planogramFb,
      nameBp,
      ts
    );
    yield put(
      result(UPDATE_PLANOGRAM_LEVENDING, {
        code,
        message: resultBody,
        ts: tsNow,
        planogram_history: historyPlanogram,
        name: nameBp,
      })
    );
  } else {
    yield put(
      result(UPDATE_PLANOGRAM_LEVENDING, {
        code: Result.FAILED,
        message: resultBody,
        ts: tsNow,
        planogram_history: historyPlanogram,
        name: nameBp,
      })
    );
  }
}
function* updatePlanogramMilano(payload) {
  yield put(loading(UPDATE_PLANOGRAM_MILANO));
  const firebaseInstance = getInstance();
  const id = safeDeepGet(payload, ['payload', 'id'], '');
  const planogram = safeDeepGet(payload, ['payload', 'planogram'], '');
  const idMap = {};
  const priceMap = {};
  const nameMap = {};
  for (const id in planogram) {
    const code = Number(id);
    const number = ('0' + code).slice(-2);
    const name = safeDeepGet(planogram, [id, 'name'], '');
    const price = safeDeepGet(planogram, [id, 'price'], '');
    const sku = safeDeepGet(planogram, [id, 'sku'], '');
    if (id != 'null') {
      idMap[number] = sku;
      nameMap[number] = name;
      priceMap[number] = price;
    }
  }
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }
  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');
  const planogramFb = {};
  for (const selection in planogram) {
    planogramFb[selection] = safeDeepGet(planogram, selection);
  }

  wsResult = yield call(setPlanogram_Milano, id, idMap, nameMap, priceMap);
  const tsNow = new Date().getTime();
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  const historyPlanogram = [];
  if (planogramFb.device_type === 'mcprov2') {
    for (const i in planogramFb) {
      if (typeof planogramFb[i] === 'object') {
        planogramFb[i]['key'] = i;
        historyPlanogram.push(planogramFb[i]);
      }
    }
  } else {
    for (const i in planogramFb) {
      planogramFb[i.toString()]['key'] = i;
      historyPlanogram.push(planogramFb[i]);
    }
  }
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    if (safeDeepGet(resultBody, 'result') == 10) {
      resultBody['message'] = 'Mesin Tidak ada Jaringan';
    }
    if (safeDeepGet(resultBody, 'result') == 7) {
      resultBody['message'] = 'Mesin tidak membalas perintah';
    }
    if (safeDeepGet(resultBody, 'result') == 4) {
      resultBody['message'] = 'Kesalahan pada data planogram';
    }
    yield put(
      result(UPDATE_PLANOGRAM_MILANO, {
        code: Result.FAILED,
        message: resultBody,
        ts: tsNow,
        planogram_history: historyPlanogram,
        name: nameBp,
      })
    );
    return;
  }
  const type = safeDeepGet(payload, ['payload', 'device_type']);

  yield call(firebaseInstance.planogram.create, id, planogramFb, nameBp, type);

  yield put(
    result(UPDATE_PLANOGRAM_MILANO, {
      code: Result.SUCCESS,
      message: resultBody,
      ts: tsNow,
      planogram_history: historyPlanogram,
      name: nameBp,
    })
  );
}
function* readPlanogramMCPro(action) {
  yield put(loading(READ_PLANOGRAM_MCPRO));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  const historyPlanogram = [];
  const data = yield call(firebaseInstance.planogram.list, id);
  const historyBody = safeDeepGet(data, 'body');
  var planogramBody = {};
  const body = {};
  for (const i in historyBody) {
    planogramBody = safeDeepGet(historyBody, [i, 'planogram']);
    body['name'] = safeDeepGet(historyBody, [i, 'name']);
    planogramBody['name'] = safeDeepGet(historyBody, [i, 'name']);
    body['id'] = safeDeepGet(historyBody, [i, 'id']);
    body['user'] = safeDeepGet(historyBody, [i, 'user']);
    body['time_stamp'] = Number(i);
  }

  historyPlanogram.push(planogramBody);
  body['planogram'] = historyPlanogram;
  // Result
  const planogramResult = {};
  const productResult = {};

  // Retrieve sensors from platform
  let wsResult = yield call(sensors, id);
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  const tsNow = new Date().getTime();
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    if (safeDeepGet(resultBody, 'result') == 10) {
      resultBody['message'] = 'Mesin Tidak ada Jaringan';
    }
    if (safeDeepGet(resultBody, 'result') == 7) {
      resultBody['message'] = 'Mesin tidak membalas perintah';
    }
    if (safeDeepGet(resultBody, 'result') == 4) {
      resultBody['message'] = 'Kesalahan pada data planogram';
    }
    yield put(
      result(READ_PLANOGRAM_MCPRO, {
        code: Result.FAILED,
        message: resultBody,
        ts: tsNow,
        planogram_history: historyPlanogram,
      })
    );
    return;
  }
  const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);
  for (const i in sensorsList) {
    const sensors = sensorsList[i];
    const sensor = safeDeepGet(sensors, ['sensor']);
    const configtype = safeDeepGet(sensors, ['configtype']);
    const param = safeDeepGet(sensors, ['param']);
    if ('config' === configtype && 'pulse_factor' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'pulse_factor', value);
    } else if ('config' === configtype && 'qris_price' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'qris_price', value);
    } else if ('config' === configtype && 'qris_duration' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'qris_duration', value);
    } else if ('config' === configtype && 'cash_price_duration' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'cash_price_duration', value);
    } else if ('config' === configtype && 'qris_price_duration' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'qris_price_duration', value);
    } else if ('config' === configtype && 'debounce_wait' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'debounce_wait', value);
    } else if ('config' === configtype && 'min_cash_denom' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'min_cash_denom', value);
    }
  }
  yield put(
    result(READ_PLANOGRAM_MCPRO, {
      code: Result.SUCCESS,
      body: {
        planogram: planogramResult,
        products: productResult,
        history: body,
      },
    })
  );
}
function* readPlanogramLeVeding(action) {
  yield put(loading(READ_PLANOGRAM_LEVENDING));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  const Bp = yield call(firebaseInstance.businessPoint.read, id);
  if (safeDeepGet(Bp, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM_LEVENDING, { code: Result.FAILED }));
    return;
  }
  let wsResult = yield call(getPlanoggramLevending, id);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const tableBody = safeDeepGet(wsResult, ['body', 'table_data']);
    const name_device = safeDeepGet(wsResult, ['body', 'device_label']);
    const tableMaps = [];
    for (const id in tableBody) {
      const price = Number(safeDeepGet(tableBody[id], ['HARGA', 'value']));
      const selection = safeDeepGet(tableBody[id], ['SELECTION', 'value']);
      const name = safeDeepGet(tableBody[id], ['NAME', 'value']);
      const sku = safeDeepGet(tableBody[id], ['SKU', 'value']);
      const order = safeDeepGet(tableBody[id], ['ORDER', 'value']);
      const ingredients = safeDeepGet(tableBody[id], ['INGREDIENT', 'value']);
      const data = {
        price: price,
        selection: selection,
        name: name,
        sku: sku,
        order: order,
      };
      // tableMaps[id] = data;
      tableMaps.push(data);
    }
    const firebaseInstance = getInstance();

    const historyPlanogram = [];
    const data = yield call(firebaseInstance.planogram.list, id);
    const historyBody = safeDeepGet(data, 'body');
    var planogramBodyLeVending = {};
    const body = {};
    for (const i in historyBody) {
      planogramBodyLeVending = safeDeepGet(historyBody, [i, 'planogram']);
      body['name'] = safeDeepGet(historyBody, [i, 'name']);
      body['id'] = safeDeepGet(historyBody, [i, 'id']);
      body['user'] = safeDeepGet(historyBody, [i, 'user']);
      body['time_stamp'] = Number(i);
    }

    for (const i in planogramBodyLeVending) {
      planogramBodyLeVending[i.toString()]['key'] = i;
      historyPlanogram.push(planogramBodyLeVending[i]);
    }
    body['planogram'] = historyPlanogram;
    yield put(
      result(READ_PLANOGRAM_LEVENDING, {
        code: Result.SUCCESS,
        body: { planogram: tableMaps, name: name_device, history: body },
      })
    );
    return;
  } else {
    yield put(result(READ_PLANOGRAM_LEVENDING, { code: Result.FAILED }));
    return;
  }
}
function* readPlanogram(action) {
  yield put(loading(READ_PLANOGRAM));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  const data = yield call(firebaseInstance.planogram.list, id);
  const historyPlanogram = [];
  const historyBody = safeDeepGet(data, 'body');
  var planogramBody = {};
  const body = {};

  for (const i in historyBody) {
    planogramBody = safeDeepGet(historyBody, [i, 'planogram']);
    body['name'] = safeDeepGet(historyBody, [i, 'name']);
    body['id'] = safeDeepGet(historyBody, [i, 'id']);
    body['user'] = safeDeepGet(historyBody, [i, 'user']);
    body['time_stamp'] = Number(i);
  }

  for (const i in planogramBody) {
    planogramBody[i]['key'] = i;
    historyPlanogram.push(planogramBody[i]);
  }
  body['planogram'] = historyPlanogram;
  // Result
  const planogramResult = {};
  const productResult = {};

  // Retrieve product
  let wsResult = yield call(firebaseInstance.product.list);

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM, { code: Result.FAILED }));
    return;
  }
  const productMap = safeDeepGet(wsResult, ['body']);
  for (const sku in productMap) {
    productResult[sku] = safeDeepGet(productMap, [sku, 'name'], '');
  }

  // Retrieve sensors from platform
  wsResult = yield call(sensors, id);
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    if (safeDeepGet(resultBody, 'result') == 10) {
      resultBody['message'] = 'Mesin Tidak ada Jaringan';
    }
    if (safeDeepGet(resultBody, 'result') == 7) {
      resultBody['message'] = 'Mesin tidak membalas perintah';
    }
    if (safeDeepGet(resultBody, 'result') == 4) {
      resultBody['message'] = 'Kesalahan pada data planogram';
    }
    yield put(
      result(READ_PLANOGRAM, { code: Result.FAILED, message: resultBody })
    );
    return;
  }
  const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);
  for (const i in sensorsList) {
    const sensors = sensorsList[i];
    const sensor = safeDeepGet(sensors, ['sensor']);
    const configtype = safeDeepGet(sensors, ['configtype']);
    const param = safeDeepGet(sensors, ['param']);
    if ('config' === configtype && 'id' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], '');
      safeDeepSet(planogramResult, [sensor, 'product'], value);
      const nameP = safeDeepGet(productMap, [value, 'name'], '');
      safeDeepSet(planogramResult, [sensor, 'nameProduct'], nameP);
    } else if ('config' === configtype && 'price' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      safeDeepSet(planogramResult, [sensor, 'price'], value);
    } else if ('config' === configtype && 'active' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], false);
      safeDeepSet(planogramResult, [sensor, 'active'], value);
    } else if ('cdata' === configtype && 'stock' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      safeDeepSet(planogramResult, [sensor, 'stock'], value);
    } else if ('config' === configtype && 'column' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      safeDeepSet(planogramResult, [sensor, 'column'], value);
    }
  }
  wsResult = yield call(firebaseInstance.businessPoint.read, id);
  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM, { code: Result.FAILED }));
    return;
  }
  const type = safeDeepGet(wsResult, ['body', 'type'], 'nagomi');
  wsResult = yield call(firebaseInstance.config.list);
  let columnPlanogram = [];
  let buttonPlanogram = [];
  if (type == 'nagomi') {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_nagomi', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_nagomi', 'button'],
      []
    );
  } else if (type == 'slant') {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_slant', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_slant', 'button'],
      []
    );
  } else if (type == 'bignagomi') {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_bignagomi', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_bignagomi', 'button'],
      []
    );
  } else if (type == 'kubota') {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_kubota', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_kubota', 'button'],
      []
    );
  } else {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram', 'button'],
      []
    );
  }
  const buttonList = [];
  for (const i in buttonPlanogram) {
    const value = buttonPlanogram[i];
    buttonList.push({ label: value, value: value });
  }
  yield put(
    result(READ_PLANOGRAM, {
      code: Result.SUCCESS,
      column: columnPlanogram,
      button: buttonPlanogram,
      type: type,
      body: {
        planogram: planogramResult,
        products: productResult,
        history: body,
      },
    })
  );
}
function* readPlanogramFranke(action) {
  yield put(loading(READ_PLANOGRAM_FRANKE));
  const deviceId = safeDeepGet(action, ['payload', 'id']);
  const wsResult = yield call(getPlanoggram, deviceId);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const tableBody = safeDeepGet(wsResult, ['body', 'table_data']);
    const name_device = safeDeepGet(wsResult, ['body', 'device_label']);
    const tableMaps = [];
    for (const id in tableBody) {
      const price = Number(safeDeepGet(tableBody[id], ['HARGA', 'value']));
      const selection = safeDeepGet(tableBody[id], ['SELECTION', 'value']);
      const name = safeDeepGet(tableBody[id], ['NAME', 'value']);
      const sku = safeDeepGet(tableBody[id], ['SKU', 'value']);
      const data = { price: price, selection: selection, name: name, sku: sku };
      // tableMaps[id] = data;
      tableMaps.push(data);
    }
    const firebaseInstance = getInstance();

    const historyPlanogram = [];
    const data = yield call(firebaseInstance.planogram.list, deviceId);
    const historyBody = safeDeepGet(data, 'body');
    var planogramBody = {};
    const body = {};
    for (const i in historyBody) {
      planogramBody = safeDeepGet(historyBody, [i, 'planogram']);
      body['name'] = safeDeepGet(historyBody, [i, 'name']);
      body['id'] = safeDeepGet(historyBody, [i, 'id']);
      body['user'] = safeDeepGet(historyBody, [i, 'user']);
      body['time_stamp'] = Number(i);
    }

    for (const i in planogramBody) {
      planogramBody[i]['key'] = i;
      historyPlanogram.push(planogramBody[i]);
    }
    body['planogram'] = historyPlanogram;
    yield put(
      result(READ_PLANOGRAM_FRANKE, {
        code: Result.SUCCESS,
        body: { planogram: tableMaps, name: name_device, history: body },
      })
    );
  } else {
    yield put(result(READ_PLANOGRAM_FRANKE, { code: Result.FAILED }));
    return;
  }
}
function* readPlanogramMilano(action) {
  yield put(loading(READ_PLANOGRAM_MILANO));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  // Result
  const planogramResult = {};
  const productList = {};
  const productResult = {};
  let wsResult = yield call(firebaseInstance.product.list);

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM_MILANO, { code: Result.FAILED }));
    return;
  }
  const productMap = safeDeepGet(wsResult, ['body']);
  // wsResult = yield call(firebaseInstance.planogram.read, id);
  // const planogramMap = safeDeepGet(wsResult, ['body']);
  for (const sku in productMap) {
    productResult[sku] = safeDeepGet(productMap, [sku, 'display_name'], '');
  }
  const historyPlanogram = [];
  const data = yield call(firebaseInstance.planogram.list, id);
  const historyBody = safeDeepGet(data, 'body');
  var planogramBody = {};
  const body = {};
  for (const i in historyBody) {
    planogramBody = safeDeepGet(historyBody, [i, 'planogram']);
    body['name'] = safeDeepGet(historyBody, [i, 'name']);
    body['id'] = safeDeepGet(historyBody, [i, 'id']);
    body['user'] = safeDeepGet(historyBody, [i, 'user']);
    body['time_stamp'] = Number(i);
  }
  for (const i in planogramBody) {
    planogramBody[i]['key'] = i;
    historyPlanogram.push(planogramBody[i]);
  }
  body['planogram'] = historyPlanogram;
  // Retrieve product
  wsResult = yield call(getPlanogramMilano, id);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(result(READ_PLANOGRAM_MILANO, { code: Result.FAILED }));
    return;
  }

  const sensorsList = safeDeepGet(wsResult, ['body', 'table_data']);
  for (const i in sensorsList) {
    const number = Number(i) + 1;
    const sensors = sensorsList[i];
    const sensor = safeDeepGet(sensors, ['selection']);
    const selection = safeDeepGet(sensors, ['SELECTION', 'value'], '');
    forceDeepSet(
      planogramResult,
      [Number(selection).toString(), 'selection'],
      selection
    );
    const sku = safeDeepGet(sensors, ['SKU', 'value'], 0);
    forceDeepSet(planogramResult, [Number(selection).toString(), 'sku'], sku);
    const name = safeDeepGet(sensors, ['NAME', 'value'], 0);
    forceDeepSet(
      planogramResult,
      [Number(selection).toString(), 'display_name'],
      name
    );
    const harga = safeDeepGet(sensors, ['HARGA', 'value'], 0);
    forceDeepSet(
      planogramResult,
      [Number(selection).toString(), 'price'],
      harga
    );
    const img_url = safeDeepGet(productMap, [sku, 'img_url'], '');
    forceDeepSet(
      planogramResult,
      [Number(selection).toString(), 'img_url'],
      img_url
    );
  }
  const planogramList = [];
  for (const id in planogramResult) {
    const name = safeDeepGet(planogramResult, [id, 'display_name']);
    planogramList.push(planogramResult[id]);
  }
  yield put(
    result(READ_PLANOGRAM_MILANO, {
      code: Result.SUCCESS,
      body: {
        planogram: planogramList,
        products: productResult,
        history: body,
      },
    })
  );
}
function* readPlanogramRetail(action) {
  yield put(loading(READ_PLANOGRAM_RETAIL));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  const data = yield call(firebaseInstance.planogram.list, id);
  const historyPlanogram = [];
  const historyBody = safeDeepGet(data, 'body');
  let planogramBody = {};
  const body = {};
  for (const i in historyBody) {
    planogramBody = safeDeepGet(historyBody, [i, 'planogram']);
    body['name'] = safeDeepGet(historyBody, [i, 'name']);
    body['id'] = safeDeepGet(historyBody, [i, 'id']);
    body['user'] = safeDeepGet(historyBody, [i, 'user']);
    body['time_stamp'] = Number(i);
  }

  for (const i in planogramBody) {
    planogramBody[i]['key'] = i;
    historyPlanogram.push(planogramBody[i]);
  }
  body['planogram'] = historyPlanogram;
  // Result
  const planogramResult = {};
  const productResult = {};
  const productImageResult = {};

  // Retrieve product
  let wsResult = yield call(firebaseInstance.product.list);

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM_RETAIL, { code: Result.FAILED }));
    return;
  }
  const productMap = safeDeepGet(wsResult, ['body']);
  for (const sku in productMap) {
    productResult[sku] = safeDeepGet(productMap, [sku, 'name'], '');
  }
  for (const sku in productMap) {
    productImageResult[sku] = safeDeepGet(productMap, [sku, 'img_url'], '');
  }
  // Retrieve sensors from platform
  wsResult = yield call(sensors, id);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(result(READ_PLANOGRAM_RETAIL, { code: Result.FAILED }));
    return;
  }
  const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);

  for (const i in sensorsList) {
    const sensors = sensorsList[i];
    const sensor = safeDeepGet(sensors, ['sensor']);
    const configtype = safeDeepGet(sensors, ['configtype']);
    const param = safeDeepGet(sensors, ['param']);
    if ('config' === configtype && 'id' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], '');
      safeDeepSet(planogramResult, [sensor, 'product'], value);
      const nameP = safeDeepGet(productMap, [value, 'name'], '');
      safeDeepSet(planogramResult, [sensor, 'nameProduct'], nameP);
      const Hpp = safeDeepGet(productMap, [value, 'Hpp'], 0);
      const Share = safeDeepGet(productMap, [value, 'Share'], 0);
      safeDeepSet(planogramResult, [sensor, 'hpp'], Hpp);
      safeDeepSet(planogramResult, [sensor, 'share'], Share);
    } else if ('config' === configtype && 'price' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      safeDeepSet(planogramResult, [sensor, 'price'], value);
    } else if ('config' === configtype && 'active' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], false);
      safeDeepSet(planogramResult, [sensor, 'active'], value);
    } else if ('cdata' === configtype && 'stock' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      safeDeepSet(planogramResult, [sensor, 'stock'], value);
    } else if ('config' === configtype && 'column' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], []);
      safeDeepSet(planogramResult, [sensor, 'column'], value);
    } else if ('config' === configtype && 'order' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      safeDeepSet(planogramResult, [sensor, 'order'], value);
    }
  }
  wsResult = yield call(firebaseInstance.businessPoint.read, id);

  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ_PLANOGRAM_RETAIL, { code: Result.FAILED }));
    return;
  }
  const type = safeDeepGet(wsResult, ['body', 'type'], 'nagomi');
  wsResult = yield call(firebaseInstance.config.list);
  let columnPlanogram = [];
  let buttonPlanogram = [];
  if (type == 'xy' || type == 'xy_multi') {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_xy', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_xy', 'button'],
      []
    );
  } else if (type == 'reyeah') {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_reyeah', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_reyeah', 'button'],
      []
    );
  } else if (type == 'gea') {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_gea', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_gea', 'button'],
      []
    );
  } else if (type == 'bluemart_2105') {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_blumart_2105', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_blumart_2105', 'button'],
      []
    );
  } else if (type == 'bluemart_5508') {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_bluemart_5508', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_bluemart_5508', 'button'],
      []
    );
  } else {
    columnPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_blumart_2106', 'column'],
      []
    );
    buttonPlanogram = safeDeepGet(
      wsResult,
      ['body', 'planogram_blumart_2106', 'button'],
      []
    );
  }

  const buttonList = [];
  if (
    type.includes('bluemart_') ||
    type.includes('xy') ||
    type.includes('gea')
  ) {
    for (const i in columnPlanogram) {
      const value = columnPlanogram[i];
      buttonList.push({ label: value, value: value });
    }
  } else {
    for (const i in buttonPlanogram) {
      const value = buttonPlanogram[i];
      buttonList.push({ label: value, value: value });
    }
  }
  for (const i in planogramResult) {
    // console.log(planogramResult[i])
    // console.log(i)
    if (i.substring(0, 1) == 'P') {
      const column = safeDeepGet(planogramResult, [i, 'column'], '');

      const arr_column = typeof column === 'string' ? column.split(',') : [];

      if (column != '') {
        for (const j in arr_column) {
          const selection = arr_column[j];
          const sku = safeDeepGet(planogramResult, [i, 'product'], '');
          const name_product = safeDeepGet(
            planogramResult,
            [i, 'nameProduct'],
            ''
          );
          const price_product = safeDeepGet(planogramResult, [i, 'price'], 0);
          const price_hpp = safeDeepGet(planogramResult, [i, 'hpp'], 0);
          const price_share = safeDeepGet(planogramResult, [i, 'share'], 0);
          safeDeepSet(planogramResult, [selection, 'product'], sku);
          safeDeepSet(
            planogramResult,
            [selection, 'nameProduct'],
            name_product
          );
          safeDeepSet(planogramResult, [selection, 'price'], price_product);
          safeDeepSet(planogramResult, [selection, 'hpp'], price_hpp);
          safeDeepSet(planogramResult, [selection, 'share'], price_share);
        }
      }
    }
  }

  yield put(
    result(READ_PLANOGRAM_RETAIL, {
      code: Result.SUCCESS,
      column: columnPlanogram,
      columnOptions: columnPlanogram,
      button: buttonPlanogram,
      buttonOptions: buttonList,
      type: type,
      body: {
        planogram: planogramResult,
        products: productResult,
        images: productImageResult,
        history: body,
      },
    })
  );
}

function* stockBusinessPoint(payload) {
  yield put(loading(STOCK_BUSINESS_POINT));
  const firebaseInstance = getInstance();
  // const value = safeDeepGet(payload, ['payload', 'stock'], '');
  const operator = safeDeepGet(payload, ['payload', 'operator'], '');
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  let allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  if (operator == 'all') {
    let wsResult = yield call(firebaseInstance.businessPoint.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const businessPointBody = wsResult.body;
      const stockList = [];
      const businessPointMap = {};
      const productList = [];
      for (const id in businessPointBody) {
        const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
        const name = safeDeepGet(businessPointBody, [id, 'name'], '');
        const address = safeDeepGet(businessPointBody, [id, 'address'], '');
        businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
        wsResult = yield call(stockPlanogram, deviceId);

        if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
          yield put(result(STOCK_BUSINESS_POINT, { code: Result.FAILED }));
          return;
        }
        let planogramResult = {};
        const productResult = {};
        const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);
        const PlannogramMap = {};
        forceDeepSet(productResult, ['bpname'], name);
        forceDeepSet(productResult, ['address'], address);
        forceDeepSet(productResult, ['planogram'], planogramResult);
        let data_filter = sensorsList.filter(
          element => element.device_id == deviceId
        );
        if (allBp == 'false') {
          data_filter = sensorsList.filter(
            element =>
              element.device_id == deviceId && arr_bp.includes(deviceId)
          );
        }
        const stockMap = {};
        const columnMap = {};

        for (const i in data_filter) {
          const sensors = data_filter[i];
          // const sensors = sensorsList[i];
          const deviceId = safeDeepGet(sensors, ['device_id']);
          if (has(businessPointMap, deviceId)) {
            let sensor = safeDeepGet(sensors, ['sensor']);
            PlannogramMap[deviceId] = sensorsList;
            const configtype = safeDeepGet(sensors, ['configtype']);
            const param = safeDeepGet(sensors, ['param']);
            let arr_value = [];
            if ('config' === configtype && 'name' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], '');
              forceDeepSet(planogramResult, [sensor, 'name'], value);
              forceDeepSet(planogramResult, [sensor, 'sensor'], sensor);
              if (!productList.includes(value)) {
                productList.push(value);
              }
            } else if ('data' === configtype && 'stock' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              forceDeepSet(planogramResult, [sensor, 'qty'], value);
              forceDeepSet(stockMap, [sensor, 'qty'], value);
            } else if ('cdata' === configtype && 'stock' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              forceDeepSet(planogramResult, [sensor, 'qty'], value);
              forceDeepSet(stockMap, [sensor, 'qty'], value);
            } else if ('config' === configtype && 'column' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              forceDeepSet(planogramResult, [sensor, 'qty'], value);
              arr_value = value.split(',');
              const sensors_column = safeDeepGet(sensors, 'sensor', '');
              if (arr_value.length > 0) {
                forceDeepSet(columnMap, sensors_column, arr_value);
              }
            }
          }
          if (allBp == 'false') {
            if (arr_bp.includes(deviceId)) {
              forceDeepSet(productResult, ['bpname'], name);
              forceDeepSet(productResult, ['id'], deviceId);
              forceDeepSet(productResult, ['address'], address);
              forceDeepSet(productResult, ['planogram'], planogramResult);
              forceDeepSet(productResult, ['dataPLanpgram'], planogramResult);
            }
          } else {
            forceDeepSet(productResult, ['bpname'], name);
            forceDeepSet(productResult, ['id'], deviceId);
            forceDeepSet(productResult, ['address'], address);
            forceDeepSet(productResult, ['planogram'], planogramResult);
            forceDeepSet(productResult, ['dataPLanpgram'], planogramResult);
          }
        }
        const qtyMap = {};

        for (const i in columnMap) {
          const arr_data = safeDeepGet(columnMap, i, []);
          var amountQty = 0;
          for (const j in arr_data) {
            const column = arr_data[j];
            const qty = safeDeepGet(stockMap, [column, 'qty'], 0);
            amountQty += qty;
          }
          forceDeepSet(qtyMap, i, amountQty);
        }

        const condStockMap = {
          empty: 0,
          warning: 0,
          ok: 0,
        };
        for (const data in qtyMap) {
          const qty = safeDeepGet(qtyMap, data, 0);
          forceDeepSet(productResult, ['planogram', data, 'qty'], qty);
        }

        const planogram = safeDeepGet(productResult, 'planogram', {});
        for (const i in planogram) {
          const name = safeDeepGet(planogram, [i, 'name'], null);

          if (!isNaN(i) || name == null) {
            safeDel(planogram, i);
          }
        }
        for (const data in planogram) {
          const qty = safeDeepGet(planogram, [data, 'qty'], 0);
          if (qty == 0) {
            forceDeepSet(
              condStockMap,
              'empty',
              safeDeepGet(condStockMap, 'empty') + 1
            );
          } else if (qty > 0 && qty <= 3) {
            forceDeepSet(
              condStockMap,
              'warning',
              safeDeepGet(condStockMap, 'warning') + 1
            );
          } else {
            forceDeepSet(
              condStockMap,
              'ok',
              safeDeepGet(condStockMap, 'ok') + 1
            );
          }
        }
        const groupedData = sortingData(planogram, 5);
        const arr = [];
        for (const group in groupedData) {
          arr.push(group);
        }
        forceDeepSet(productResult, 'planogram', groupedData);
        forceDeepSet(productResult, 'arr', arr);
        forceDeepSet(productResult, 'conditional', condStockMap);

        stockList.push(productResult);
      }
      for (const i in stockList) {
        const planogram = safeDeepGet(stockList[i], 'dataPLanpgram', {});
        const product = {};
        const arr_product = [];
        for (const j in planogram) {
          const name = safeDeepGet(planogram, [j, 'name'], '');
          arr_product.push(name);
          const qty = safeDeepGet(planogram, [j, 'qty'], 0);
          if (product[name] === undefined) {
            safeDeepSet(product, name, qty);
          } else {
            product[name] += qty;
          }
        }
        for (const i in productList) {
          const name = productList[i];
          if (!arr_product.includes(name)) {
            safeDeepSet(product, name, 0);
          }
        }
        safeDeepSet(stockList[i], 'product', product);
      }
      yield put(
        result(STOCK_BUSINESS_POINT, {
          code: Result.SUCCESS,
          body: stockList,
        })
      );
    }
  } else {
    let wsResult = yield call(firebaseInstance.businessPoint.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      let number = null;
      let operatorw = '=';
      if (operator == 'tersedia') {
        number = 3;
        operatorw = '>';
      } else if (operator == 'empty') {
        number = 1;
        operatorw = '<';
      } else if (operator == 'warning') {
        number = 3;
        operatorw = '<=';
      }

      const businessPointBody = wsResult.body;
      const stockList = [];
      const businessPointMap = {};
      for (const id in businessPointBody) {
        const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
        const name = safeDeepGet(businessPointBody, [id, 'name'], '');
        const address = safeDeepGet(businessPointBody, [id, 'address'], '');
        businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
        wsResult = yield call(stockEmpty, number, operatorw);
        if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
          yield put(result(STOCK_BUSINESS_POINT, { code: Result.FAILED }));
          return;
        }
        const planogramResult = {};
        const productResult = {};
        const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);
        const PlannogramMap = {};
        forceDeepSet(productResult, ['bpname'], name);
        forceDeepSet(productResult, ['address'], address);
        forceDeepSet(productResult, ['planogram'], planogramResult);
        const data_filter = sensorsList.filter(
          element => element.device_id == deviceId
        );
        const stockMap = {};
        const columnMap = {};
        for (const i in data_filter) {
          const sensors = data_filter[i];
          const deviceId = safeDeepGet(sensors, ['device_id']);
          if (has(businessPointMap, deviceId)) {
            let sensor = safeDeepGet(sensors, ['sensor']);
            if (Number(sensor.substring(0, 1)) != NaN) {
              sensor = 'K' + sensor;
            } else {
              if (sensor.substring(0, 1) == 'P') {
                sensor = 'K' + sensor.substring(1, sensor.length);
              }
            }
            PlannogramMap[deviceId] = data_filter;
            const configtype = safeDeepGet(sensors, ['configtype']);
            const param = safeDeepGet(sensors, ['param']);
            let arr_value = [];
            if ('config' === configtype && 'name' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], '');
              forceDeepSet(planogramResult, [sensor, 'name'], value);
              forceDeepSet(planogramResult, [sensor, 'sensor'], sensor);
            } else if ('data' === configtype && 'stock' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              forceDeepSet(planogramResult, [sensor, 'qty'], value);
              forceDeepSet(stockMap, [sensor, 'qty'], value);
            } else if ('cdata' === configtype && 'stock' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              forceDeepSet(planogramResult, [sensor, 'qty'], value);
              forceDeepSet(stockMap, [sensor, 'qty'], value);
            } else if ('config' === configtype && 'column' === param) {
              const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
              // forceDeepSet(planogramResult, [sensor, 'qty'], value);
              arr_value = value.split(',');
              const sensors_column = safeDeepGet(sensors, 'sensor', '');
              if (arr_value.length > 0) {
                forceDeepSet(columnMap, sensors_column, arr_value);
              }
            }
          }
          if (allBp == 'false') {
            if (arr_bp.includes(deviceId)) {
              forceDeepSet(productResult, ['bpname'], name);
              forceDeepSet(productResult, ['id'], deviceId);
              forceDeepSet(productResult, ['address'], address);
              forceDeepSet(productResult, ['planogram'], planogramResult);
            }
          } else {
            forceDeepSet(productResult, ['bpname'], name);
            forceDeepSet(productResult, ['id'], deviceId);
            forceDeepSet(productResult, ['address'], address);
            forceDeepSet(productResult, ['planogram'], planogramResult);
          }
        }
        const qtyMap = {};
        for (const i in columnMap) {
          const arr_data = safeDeepGet(columnMap, i, []);
          let amountQty = 0;
          for (const j in arr_data) {
            const column = arr_data[j];
            const qty = safeDeepGet(stockMap, [column, 'qty'], 0);
            amountQty += qty;
          }
          forceDeepSet(qtyMap, i, amountQty);
        }
        for (const data in qtyMap) {
          const qty = safeDeepGet(qtyMap, data, 0);
          forceDeepSet(productResult, ['planogram', data, 'qty'], qty);
        }
        const planogram = safeDeepGet(productResult, 'planogram', {});
        for (const i in planogram) {
          if (!isNaN(i)) {
            safeDel(planogram, i);
          }
        }
        const planogramMaps = safeDeepGet(productResult, ['planogram']);
        const groupedData = sortingData(planogram, 5);
        const arr = [];
        for (const group in groupedData) {
          arr.push(group);
        }
        forceDeepSet(productResult, 'planogram', groupedData);
        forceDeepSet(productResult, 'arr', arr);
        if (operator == 'warning') {
          let filterWarning = {};
          for (const i in planogramMaps) {
            if (planogramMaps[i].qty > 0) {
              filterWarning[i] = planogramMaps[i];
            }
          }
          forceDeepSet(productResult, ['planogram'], filterWarning);
        }
        stockList.push(productResult);
      }
      yield put(
        result(STOCK_BUSINESS_POINT, {
          code: Result.SUCCESS,
          body: stockList,
        })
      );
    }
  }
}

function sortingData(planogram, numberOfGroups) {
  const data = [];
  for (const i in planogram) {
    data.push({ id: i, ...planogram[i] });
  }
  data.sort((a, b) => parseInt(a.id.slice(1)) - parseInt(b.id.slice(1)));
  return groupedData(data);
}
function groupedData(planogram) {
  const chunkSize = 3;
  const data = [];
  const arrayOfArrays = planogram.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  arrayOfArrays.forEach(array => {
    data.push(array);
  });
  return data;
}
function* updatePlanogramWater(action) {
  yield put(loading(UPDATE_PLANOGRAM_WATER_DISPENSER));
  const id = safeDeepGet(action, ['payload', 'device_id']);
  const type = safeDeepGet(action, ['payload', 'device_type']);
  const payload = safeDeepGet(action, 'payload', {});
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }
  const durationCup = safeDeepGet(payload, 'durationCup', 99999999999);
  safeDel(payload, 'durationCup');
  safeDeepSet(payload, 'durationCup', Number(durationCup));
  const priceCup = safeDeepGet(payload, 'priceCup', 99999999999);
  safeDel(payload, 'priceCup');
  safeDeepSet(payload, 'priceCup', Number(priceCup));
  const stockCup = safeDeepGet(payload, 'stockCup', 99999999999);
  safeDel(payload, 'stockCup');
  safeDeepSet(payload, 'stockCup', Number(stockCup));
  const priceWater = safeDeepGet(payload, 'priceWater', 99999999999);
  safeDel(payload, 'priceWater');
  safeDeepSet(payload, 'priceWater', Number(priceWater));
  const durationWater = safeDeepGet(payload, 'durationWater', 99999999999);
  safeDel(payload, 'durationWater');
  safeDeepSet(payload, 'durationWater', Number(durationWater));
  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');

  const cupWaterMap = {};
  const waterMap = {};

  cupWaterMap['duration'] = safeDeepGet(payload, 'durationCup', 99999999999);
  cupWaterMap['price'] = safeDeepGet(payload, 'priceCup', 99999999999);
  cupWaterMap['stock'] = safeDeepGet(payload, 'stockCup', 99999999999);

  waterMap['duration'] = safeDeepGet(payload, 'durationWater', 99999999999);
  waterMap['price'] = safeDeepGet(payload, 'priceWater', 9999999999);
  const body = {
    cupWaterMap,
    waterMap,
  };

  // Save price to platform
  wsResult = yield call(setPlanogramWater, payload);
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  const historyPlanogram = [];
  if (payload.device_type === 'mcprov2') {
    for (const i in payload) {
      if (typeof payload[i] === 'object') {
        payload[i]['key'] = i;
        historyPlanogram.push(payload[i]);
      }
    }
  } else {
    for (const i in payload) {
      payload[i.toString()]['key'] = i;
      historyPlanogram.push(payload[i]);
    }
  }
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    if (safeDeepGet(resultBody, 'result') == 10) {
      resultBody['message'] = 'Mesin Tidak ada Jaringan';
    }
    if (safeDeepGet(resultBody, 'result') == 7) {
      resultBody['message'] = 'Mesin tidak membalas perintah';
    }
    if (safeDeepGet(resultBody, 'result') == 4) {
      resultBody['message'] = 'Kesalahan pada data planogram';
    }

    yield put(
      result(UPDATE_PLANOGRAM_WATER_DISPENSER, {
        code: Result.FAILED,
        message: resultBody,
      })
    );
    return;
  }
  yield call(firebaseInstance.planogram.create, id, payload, nameBp, type);

  yield put(
    result(UPDATE_PLANOGRAM_WATER_DISPENSER, {
      code: Result.SUCCESS,
      message: safeDeepGet(wsResult, ['body', 'message'], ''),
    })
  );
}
function* readPlanogramWater(action) {
  yield put(loading(READ_PLANOGRAM_WATER_DISPENSER));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  const historyPlanogram = [];
  const data = yield call(firebaseInstance.planogram.list, id);
  const historyBody = safeDeepGet(data, 'body');
  var planogramBody = {};
  const body = {};
  for (const i in historyBody) {
    planogramBody = safeDeepGet(historyBody, [i, 'planogram']);
    body['name'] = safeDeepGet(historyBody, [i, 'name']);
    planogramBody['name'] = safeDeepGet(historyBody, [i, 'name']);
    body['id'] = safeDeepGet(historyBody, [i, 'id']);
    body['user'] = safeDeepGet(historyBody, [i, 'user']);
    body['time_stamp'] = Number(i);
  }

  historyPlanogram.push(planogramBody);
  body['planogram'] = historyPlanogram;
  // Result
  const planogramResult = {};
  const productResult = {};

  // Retrieve sensors from platform
  let wsResult = yield call(sensors, id);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(result(READ_PLANOGRAM_WATER_DISPENSER, { code: Result.FAILED }));
    return;
  }
  const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);
  for (const i in sensorsList) {
    const sensors = sensorsList[i];
    const sensor = safeDeepGet(sensors, ['sensor']);
    const configtype = safeDeepGet(sensors, ['configtype']);
    const param = safeDeepGet(sensors, ['param']);
    if ('config' === configtype && 'duration' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, [sensor, 'duration'], value);
    } else if ('config' === configtype && 'name' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, [sensor, 'name'], value);
    } else if ('config' === configtype && 'price' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, [sensor, 'price'], value);
    } else if ('config' === configtype && 'id' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, [sensor, 'id'], value);
    } else if ('cdata' === configtype && 'stock' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, [sensor, 'stock'], value);
    } else if ('data' === configtype && 'total' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, [sensor, 'total'], value);
    }
  }
  yield put(
    result(READ_PLANOGRAM_WATER_DISPENSER, {
      code: Result.SUCCESS,
      body: {
        planogram: planogramResult,
        products: productResult,
        history: body,
      },
    })
  );
}
function* reset_flowmeter(action) {
  yield put(loading(RESET_FLOWMETER));
  const device_id = safeDeepGet(action, ['payload', 'device_id'], '');
  const payload = {
    device_id: device_id,
    reset_flow: true,
  };
  const wsResult = yield call(resetFlowmeter, payload);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.HTTP.OK) {
    yield put(
      result(RESET_FLOWMETER, { code: Result.SUCCESS, message: wsResult })
    );
    return;
  }
  yield put(
    result(RESET_FLOWMETER, { code: Result.FAILED, message: wsResult })
  );
}
function* notification() {
  yield put(loading(NOTIFICATIONS));
  const wsResult = yield call(get_notifications);
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const refundList = [];
    const complaintList = [];
    const notificationBody = safeDeepGet(wsResult, 'body');
    const notificationList = [];
    for (const i in notificationBody) {
      const type = safeDeepGet(notificationBody[i], 'type');
      if (type == 'refund' || type == 'complaint') {
        if (type == 'refund') {
          refundList.push(notificationBody[i]);
        } else if (type == 'complaint') {
          complaintList.push(notificationBody[i]);
        }
        notificationList.push(notificationBody[i]);
      }
    }
    const body = {
      refundList,
      complaintList,
    };
    const length = {
      refundList: refundList.length,
      complaintList: complaintList.length,
      all: notificationList.length,
    };
    yield put(
      result(NOTIFICATIONS, {
        code: Result.SUCCESS,
        body: body,
        length,
      })
    );
  } else {
    yield put(result(NOTIFICATIONS, { code: Result.FAILED }));
  }
}
function* updatePlanogramArcade(action) {
  yield put(loading(UPDATE_PLANOGRAM_ARCADE));
  const id = safeDeepGet(action, ['payload', 'device_id']);
  const type = safeDeepGet(action, ['payload', 'device_type']);
  const payload = safeDeepGet(action, 'payload', {});
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  const businessPointBody = wsResult.body;
  const businessPointMap = {};
  for (const id in businessPointBody) {
    const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
    businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
  }
  const nameBp = safeDeepGet(businessPointMap, [id, 'name'], '-');

  const price = safeDeepGet(payload, 'price', 999999);
  const max = safeDeepGet(payload, 'max', 10);
  const min_coin = safeDeepGet(payload, 'minCoin', 10);
  const priceMap = {
    price: Number(price),
    max: Number(max),
    min_coin: Number(min_coin),
  };
  safeDel(payload, 'price');
  safeDel(payload, 'max');
  safeDel(payload, 'minCoin');
  safeDeepSet(payload, 'price', priceMap);

  wsResult = yield call(setPlanogramArcade, payload);
  const resultBody = safeDeepGet(wsResult, ['body', 'body'], {});
  const historyPlanogram = [];
  if (payload.device_type === 'mcprov2') {
    for (const i in payload) {
      if (typeof payload[i] === 'object') {
        payload[i]['key'] = i;
        historyPlanogram.push(payload[i]);
      }
    }
  } else {
    for (const i in payload) {
      payload[i.toString()]['key'] = i;
      historyPlanogram.push(payload[i]);
    }
  }
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    if (safeDeepGet(resultBody, 'result') == 10) {
      resultBody['message'] = 'Mesin Tidak ada Jaringan';
    }
    if (safeDeepGet(resultBody, 'result') == 7) {
      resultBody['message'] = 'Mesin tidak membalas perintah';
    }
    if (safeDeepGet(resultBody, 'result') == 4) {
      resultBody['message'] = 'Kesalahan pada data planogram';
    }
    yield put(
      result(UPDATE_PLANOGRAM_ARCADE, {
        code: Result.FAILED,
        message: resultBody,
      })
    );
    return;
  }
  yield call(firebaseInstance.planogram.create, id, payload, nameBp, type);

  yield put(
    result(UPDATE_PLANOGRAM_ARCADE, {
      code: Result.SUCCESS,
      message: safeDeepGet(wsResult, ['body', 'message'], ''),
    })
  );
}
function* readPlanogramArcade(action) {
  yield put(loading(READ_PLANOGRAM_ARCADE));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  const historyPlanogram = [];
  const data = yield call(firebaseInstance.planogram.list, id);
  const historyBody = safeDeepGet(data, 'body');
  var planogramBody = {};
  const body = {};
  for (const i in historyBody) {
    planogramBody = safeDeepGet(historyBody, [i, 'planogram']);
    body['name'] = safeDeepGet(historyBody, [i, 'name']);
    planogramBody['name'] = safeDeepGet(historyBody, [i, 'name']);
    body['id'] = safeDeepGet(historyBody, [i, 'id']);
    body['user'] = safeDeepGet(historyBody, [i, 'user']);
    body['time_stamp'] = Number(i);
  }

  historyPlanogram.push(planogramBody);
  body['planogram'] = historyPlanogram;
  // Result
  const planogramResult = {};
  const productResult = {};

  // Retrieve sensors from platform
  let wsResult = yield call(sensors, id);
  if (safeDeepGet(wsResult, 'code') !== Result.HTTP.OK) {
    yield put(result(READ_PLANOGRAM_ARCADE, { code: Result.FAILED }));
    return;
  }
  const sensorsList = safeDeepGet(wsResult, ['body', 'sensors']);
  for (const i in sensorsList) {
    const sensors = sensorsList[i];
    const sensor = safeDeepGet(sensors, ['sensor']);
    const configtype = safeDeepGet(sensors, ['configtype']);
    const param = safeDeepGet(sensors, ['param']);
    if ('config' === configtype && 'pulse_factor' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'pulse_factor', value);
    } else if ('config' === configtype && 'price' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'price', value);
    } else if ('config' === configtype && 'min_cash_denom' === param) {
      const value = safeDeepGet(sensors, ['latest_data', 'value'], 0);
      forceDeepSet(planogramResult, 'min_cash_denom', value);
    }
  }

  yield put(
    result(READ_PLANOGRAM_ARCADE, {
      code: Result.SUCCESS,
      body: {
        planogram: planogramResult,
        products: productResult,
        history: body,
      },
    })
  );
}
function* closeNotification(payload) {
  yield put(loading(CLOSE_NOTIFICATIONS));
  const task_id = safeDeepGet(payload, ['payload', 'id'], '');
  const wsResult = yield call(close_notification, btoa(task_id));
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    yield put(result(CLOSE_NOTIFICATIONS, { code: Result.SUCCESS }));
  } else {
    yield put(result(CLOSE_NOTIFICATIONS, { code: Result.FAILED }));
  }
}
function* updateShareRevenue(action) {
  yield put(loading(UPDATE_SHARE_REVENUE));
  const firebaseInstance = getInstance();
  const body = safeDeepGet(action, 'payload', {});
  const device_id = safeDeepGet(body, 'id', '');
  const type = safeDeepGet(body, 'type', '');
  const share_amount = safeDeepGet(body, 'share_amount', '');
  const updatedValue = {};
  if (type == 'fixed') {
    updatedValue['type'] = type;
    updatedValue['share_amount'] = Number(share_amount);
  } else if (type == 'share_revenue') {
    updatedValue['type'] = type;
    updatedValue['share_amount'] = Number(1 - share_amount / 100);
  }
  updatedValue['id'] = device_id;
  const wsResult = yield call(firebaseInstance.bpfee.updateFee, updatedValue);

  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS) {
    yield put(result(UPDATE_SHARE_REVENUE, { code: Result.SUCCESS }));
  } else {
    yield put(result(UPDATE_SHARE_REVENUE, { code: Result.FAILED }));
  }
}

function* getShareRevenue(action) {
  yield put(loading(GET_SHARE_REVENUE));
  const firebaseInstance = getInstance();
  const device_id = safeDeepGet(action, 'payload', {});
  const wsResult = yield call(firebaseInstance.bpfee.read, device_id);
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS) {
    const body = safeDeepGet(wsResult, 'body', {});
    yield put(result(GET_SHARE_REVENUE, { code: Result.SUCCESS, body: body }));
  } else {
    yield put(result(GET_SHARE_REVENUE, { code: Result.FAILED }));
  }
}

function* businessPointSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_VM, list_vm);
  yield takeEvery(LIST_PRODUCT, list_product);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ_INFO, readInfo);
  yield takeEvery(UPDATE_INFO, updateInfo);
  yield takeEvery(UPDATE_CALIBRATION, updatecalibration);
  yield takeEvery(READ_PRODUCT, readproduct);
  yield takeEvery(STOCK_BUSINESS_POINT, stockBusinessPoint);
  yield takeEvery(HISTORY, list_history);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(CLEAR_CASH, clear_cash);
  yield takeEvery(NOTIFICATIONS, notification);
  yield takeEvery(CLOSE_NOTIFICATIONS, closeNotification);
  yield takeEvery(UPDATE_PLANOGRAM_MCPRO, updatePlanogramMCPro);
  yield takeEvery(UPDATE_PLANOGRAM_FRANKE, updatePlanogramFranke);
  yield takeEvery(UPDATE_PLANOGRAM_RETAIL_OLD, updatePlanogramRetailOld);
  yield takeEvery(UPDATE_PLANOGRAM_LEVENDING, updatePlanogramLevending);
  yield takeEvery(UPDATE_PLANOGRAM_MILANO, updatePlanogramMilano);
  yield takeEvery(UPDATE_PLANOGRAM, updatePlanogram);
  yield takeEvery(UPDATE_PLANOGRAM_RETAIL, updatePlanogramRetail);
  yield takeEvery(UPDATE_PLANOGRAM_ARCADE, updatePlanogramArcade);
  yield takeEvery(UPDATE_SHARE_REVENUE, updateShareRevenue);
  yield takeEvery(GET_SHARE_REVENUE, getShareRevenue);
  yield takeEvery(READ_PLANOGRAM_MILANO, readPlanogramMilano);
  yield takeEvery(READ_PLANOGRAM, readPlanogram);
  yield takeEvery(READ_PLANOGRAM_LEVENDING, readPlanogramLeVeding);
  yield takeEvery(READ_PLANOGRAM_FRANKE, readPlanogramFranke);
  yield takeEvery(READ_PLANOGRAM_MCPRO, readPlanogramMCPro);
  yield takeEvery(READ_PLANOGRAM_RETAIL, readPlanogramRetail);
  yield takeEvery(READ_PLANOGRAM_RETAIL_OLD, readPlanogramRetail);
  yield takeEvery(READ_PLANOGRAM_ARCADE, readPlanogramArcade);

  yield takeEvery(UPDATE_PLANOGRAM_WATER_DISPENSER, updatePlanogramWater);
  yield takeEvery(RESET_FLOWMETER, reset_flowmeter);
  yield takeEvery(READ_PLANOGRAM_WATER_DISPENSER, readPlanogramWater);
}

export default businessPointSaga;
